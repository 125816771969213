const dialog = require('../../dialog');
const validator = require('../../validator');

const MFA_DIALOG_SELECTOR = '#mfa-dialog';
const MFA_INPUT_SELECTOR = '#dwfrm_login_otp';
const VERIFY_OTP_SELECTOR = '#cta-verifyotp';

const $body = $('body');

function handleKeyPress() {
    $body.on('keypress', MFA_INPUT_SELECTOR, (event) => {
        if (event.key === 'Enter') {
            // Prevent default form submission
            event.preventDefault();

            // Focus and Trigger click event on submit button
            $(VERIFY_OTP_SELECTOR).trigger('focus').trigger('click');
        }
    });
}

function openMfaDialog() {
    const $mfaDialogContainer = $(MFA_DIALOG_SELECTOR);
    const mfaDialogUrl = $mfaDialogContainer.attr('href');
    dialog.open({
        url: mfaDialogUrl,
        options: {
            title: Resources.AUTHENTICATION,
            width: 300,
            draggable: false,
            modal: true,
            closeOnEscape: false,
            dialogClass: 'no-close',
            open() {
                validator.init();
                document.getElementById(MFA_INPUT_SELECTOR).focus();
            },
            beforeClose() {
                // Prevent closing when clicking outside
                return false;
            },
        },
    });
}

module.exports = {
    MFA_DIALOG_SELECTOR,
    openMfaDialog,
    handleKeyPress,
};
