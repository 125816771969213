const dialog = require('./dialog');
const page = require('./page');
const util = require('./util');
const validator = require('./validator');
const mfaDialog = require('./pages/login/mfaDialog');

const login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init() {
        // o-auth binding for which icon is clicked
        $('.oAuthIcon').bind('click', (event) => {
            $('#OAuthProvider').val(event.currentTarget.id);
        });

        // toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').bind('change', () => {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });

        const $passwordReset = $('#password-reset');
        $passwordReset.on('click', (e) => {
            e.preventDefault();
            const $target = $(e.target);
            const isLegacy = $target.hasClass('legacy');
            const url = $target.attr('href');
            dialog.open({
                url: isLegacy ? util.appendParamToURL(url, 'email', $('.email input').val()) : url,
                options: {
                    title: isLegacy ? Resources.TITLE_FORGOTPASSWORDLEGACY : Resources.TITLE_FORGOTPASSWORD,
                    dialogClass: 'ui-dialog-resetpass',
                    height: 'auto',
                    open() {
                        validator.init();
                        const $requestPasswordForm = $('[name$="_requestpassword"]');
                        const $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                        $($submit).on('click', (event) => {
                            if (!$requestPasswordForm.valid()) {
                                return;
                            }
                            event.preventDefault();
                            let data = $requestPasswordForm.serialize();
                            // add form action to data
                            data += `&${$submit.attr('name')}=`;
                            // make sure the server knows this is an ajax request
                            if (data.indexOf('ajax') === -1) {
                                data += '&format=ajax';
                            }
                            $.ajax({
                                type: 'POST',
                                url: $requestPasswordForm.attr('action'),
                                data,
                                success(response) {
                                    if (typeof response === 'object'
                                            && !response.success
                                            && response.error === 'CSRF Token Mismatch') {
                                        page.redirect(Urls.csrffailed);
                                    } else if (typeof response === 'string') {
                                        dialog.$container.html(response);
                                    }
                                },
                                failure() {
                                    dialog.$container.html(`<h1>${Resources.SERVER_ERROR}</h1>`);
                                },
                            });
                        });
                    },
                },
            });
        });

        // Trigger password reset dialog if marked as legacy
        if ($passwordReset.hasClass('legacy')) {
            $passwordReset.click();
        }

        const $mfaDialogTarget = $(mfaDialog.MFA_DIALOG_SELECTOR);
        if ($mfaDialogTarget.length) {
            mfaDialog.openMfaDialog();
            mfaDialog.handleKeyPress();
        }
    },
};

module.exports = login;
